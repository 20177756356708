export function getTotalData(params) {
  return request(`/user/statistics/v1/total`, {
    method: 'GET',
    params
  })
}

export function getCategoryData(params) {
  return request(`/user/item-type/v1/statistics`, {
    method: 'GET',
    params
  })
}
export function indexpageListData(params) {
  return request(`/user/skin/v1/statistics/page`, {
    method: 'POST',
    params
  })
}

export function getItemDetailWithHashName(params) {
  return request(`/user/skin/v1/item`, {
    method: 'POST',
    params
  })
}

export function getItemDetail(params) {
  return request(`/user/skin/v1/sale-detail`, {
    method: 'POST',
    params
  })
}

export function filterData(params) {
  return oRequest(`/user/skin/v1/attribute/${params.appId}`, {
    method: 'POST',
    params
  })
}
export function filterDatav2(params) {
  return oRequest(`/user/skin/v2/attribute/${params.appId}`, {
    method: 'POST',
    params
  })
}

export function itemTracker(params) {
  return request(`/user/skin/v1/trace`, {
    method: 'POST',
    params
  })
}

export function getonsaleList(params) {
  return request(`/user/skin/v1/sale-list`, {
    method: 'POST',
    params
  })
}

export function getWearRank(params) {
  return request(`/user/skin/v2/asset/wear-rank`, {
    method: 'POST',
    params
  })
}

export function steamLogin(params) {
  return request(`/user/login/v1/steam`, {
    method: 'POST',
    params
  })
}
export function getDeviceId(params) {
  return request(`/common/v1/deviceId`, {
    method: 'GET',
    params
  })
}
export function getLeastTraceTime(params) {
  return request(`/user/skin/v1/trace/times`, {
    method: 'GET',
    params
  })
}
export function getUserInfo(params) {
  return request(`/user/v1/info`, {
    method: 'GET',
    params
  })
}
export function userLogout(params) {
  return request(`/user/login/v1/logout`, {
    method: 'POST',
    params
  })
}

export function getShareCode(params) {
  return request(`/user/activity/v1/skin/share-info`, {
    method: 'GET',
    params
  })
}

export function getTraceSkinInfo(params) {
  return request(`/user/skin/v1/skin-info`, {
    method: 'GET',
    params
  })
}

export function getTraceInfo(params) {
  return request(`/user/skin/v1/skin-trace`, {
    method: 'GET',
    params
  })
}

export function getshareReward(params) {
  return request(`/user/activity/v1/skin/share-award`, {
    method: 'POST',
    params
  })
}

export function getWearRankTop(params) {
  return request(`/user/skin/v1/wear-rank/top`, {
    method: 'GET',
    params
  })
}

export function getFingerPrint(params) {
  return request(`/user/steam/v1/asset/detail/fingerprint/${params.id}${params.assetId ? `/assetid/${params.assetId}` : ''}`, {
    method: 'GET',
    params
  })
}

export function getTraceSkinInfoV2(params) {
  return request(`/user/skin/v2/skin-info`, {
    method: 'GET',
    params
  })
}

// 趋势分析
export function getTendList(params) {
  return request(`/user/skin/v1/trend/list`, {
    method: 'POST',
    params
  })
}

// 最新新增饰品列表
export function getNewestItems(params) {
  return request(`/user/skin/v1/recent-add`, {
    method: 'GET',
    params
  })
}

// 市场列表
export function getMarketList(params) {
  return request(`/skin/market/v1/list`, {
    method: 'POST',
    params
  })
}
// 收藏操作
export function collectItem(params) {
  return request(`/user/skin/v1/collect`, {
    method: 'POST',
    params
  })
}
// 我的收藏列表
export function myCollectList(params) {
  return request(`/user/skin/v1/collection`, {
    method: 'GET',
    params
  })
}

export function getMarketListByIds(params) {
  return request(`/skin/market/v1/batch`, {
    method: 'POST',
    params
  })
}
// 搜索自动补全
export function getAutoComletion(params) {
  return request(`/user/skin/v1/auto-completion`, {
    method: 'GET',
    params
  })
}

// 查询K线
export function getKline(params) {
  return request(`/user/statistics/v1/kline`, {
    method: 'GET',
    params
  })
}

// 查询时线、日线
export function getChart(params) {
  return request(`/user/statistics/v1/chart`, {
    method: 'GET',
    params
  })
}

// 饰品市场分析
export function getTypeTrend(params) {
  return request(`/user/steam/type-trend/v2/item/details`, {
    method: 'POST',
    params
  })
}
//
export function getItemDesc(params) {
  return request(`/skin/market/v2/decoration-market-analysis`, {
    method: 'GET',
    params
  })
}

// 获取当前在售
export function getItemOnsale(params) {
  return request(`/user/skin/v1/current-sell`, {
    method: 'GET',
    params
  })
}
// 根据type获取文章
export function getArtical(params) {
  return request('/cms/article/v1/list', {
    method: 'GET',
    params
  })
}

// 根据marketHashName获取外观及category信息
export function getRelateList(params) {
  return request('/user/skin/v1/sale-wear-detail', {
    method: 'POST',
    params
  })
}
// 获取第一级分类信息
export function getFirstTrendList(params) {
  return request('/user/steam/type-trend/v2/firstOrderList', {
    method: 'POST',
    params
  })
}
// 获取二三级分类信息
export function getTypeTrendList(params) {
  return request('/user/steam/type-trend/v2/list', {
    method: 'POST',
    params
  })
}

export function getTypeTrendDetail(params) {
  return request('/user/steam/type-trend/v2/details', {
    method: 'POST',
    params
  })
}

export function getTypeAnalysis(params) {
  return request('/skin/market/v1/decoration-type-market-analysis', {
    method: 'POST',
    params
  })
}
// 短英文名称查询最小磨损名称
export function findMinWear(params) {
  return request('/user/skin/v1/find-min-wear-name', {
    method: 'POST',
    params
  })
}

// 一天数据查询
export function getOneDayDataQueryEcharts(params) {
  return request('/user/steam/reptile-item-trade-history/v1/oneDayDataQueryEcharts', {
    method: 'POST',
    params
  })
}

// 区间详细数据查询
export function getIntervalDetailsDataQuery(params) {
  return request('/user/steam/reptile-item-trade-history/v1/intervalDetailsDataQuery', {
    method: 'POST',
    params
  })
}

// 节点对比查询
export function getNodeComparisonQuery(params) {
  return request('/user/steam/steam-type-trend-all-snapshot/v1/nodeComparisonQuery', {
    method: 'POST',
    params
  })
}

// 获取默认节点
export function getDefaultNode(params) {
  return request('/user/steam/steam-type-trend-all-snapshot/v1/defaultNode', {
    method: 'GET',
    params
  })
}

// 查询K线
export function getKlineV2(params) {
  return request('/user/steam/category/v1/kline', {
    method: 'GET',
    params
  })
}

// 详情页-市场对比
export function getMarketCompare(params) {
  return request('/user/skin/v1/market-comparsion', {
    method: 'GET',
    params
  })
}

// 排行榜-查询列表
export function rankingPage(params) {
  return request('/user/ranking/v1/page', {
    method: 'POST',
    params
  })
}

// 排行榜-筛选条件
export function rankingFilter(params) {
  return request('/user/ranking/v1/attribute/730', {
    method: 'GET',
    params
  })
}

// 挂刀榜-列表
export function getKnifeRank(params) {
  return request('/user/ranking/v1/hanging-knife', {
    method: 'POST',
    params
  })
}
